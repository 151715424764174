<template>
  <the-mask
    v-model="data"
    :mask="['### ### ###', '### ### ####']"
    class="form-control"
    @blur.native="blur"
    :disabled="disabled"
    type="tel"
    :placeholder="placeholder"
  />
</template>

<script>
import { TheMask } from "vue-the-mask";

export default {
  components: {
    TheMask,
  },
  props: {
    placeholder: {},
    name: {},
    disabled: {},
    value: {},
    prefix: {},
    errors: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      data: null,
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
    };
  },
  watch: {
    data: function (value) {
      this.$emit("input", value);
    },
    value: function (value) {
      if (value) {
        this.data = value;
      }
    },
  },
  methods: {
    blur() {
      this.$emit("blur");
    },
  },
};
</script>

<style scoped>
</style>
