<template>
  <v-select
    v-model="selected"
    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    multiple
    :label="selectionLabel"
    :disabled="readonly"
    :placeholder="placeholder"
    :options="option"
    :loading="loading"
    :reduce="(item) => item[selectionKey]"
    @search="getOption"
    @open="open"
    class="n-async-multi-select"
  >
    <template #no-options>
      {{ $t("selection.noOption") }}
    </template>
    <template #spinner="{ loading }">
      <b-spinner
        v-if="loading"
        variant="primary"
        label="Spinning"
      ></b-spinner>
    </template>
  </v-select>
</template>

<script>
import vSelect from "vue-select";
import { BSpinner } from "bootstrap-vue";

import RepositoryFactory from "@/repositories/RepositoryFactory";

export default {
  components: {
    vSelect,
    BSpinner,
  },
  props: {
    placeholder: {},
    name: {},
    disabled: {},
    readonly: {},
    repository: {},
    selectionKey: {
      type: String,
      default: 'id',
    },
    selectionLabel: {
      type: String,
      default: 'name',
    },
    initOptions: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      option: [],
      selected: [],
      Repository: null,
      loading: false,
      loadedInitOptions: false,
    };
  },
  watch: {
    selected: function (value) {
      this.$emit("input", value);
    },
    initOptions: function (value) {
      if (!this.loadedInitOptions && value.length) {
        this.getOption();
      }
    },
  },
  created() {
    this.Repository = RepositoryFactory.get(this.repository);
  },
  methods: {
    open() {
      if (!this.option.length) {
        this.getOption();
      }
    },
    mapExcludeIds() {
      let excludeIds = [...this.option];

      return excludeIds.map((item) => {
        return item[this.selectionKey];
      });
    },
    mergeArray(arrayA, arrayB) {
      return arrayA.concat(arrayB).reduce((accumulator, currentValue) => {
        if (!accumulator.some((item) => { return item[this.selectionKey] === currentValue[this.selectionKey] })) {
          accumulator.push(currentValue);
        }

        return accumulator;
      }, []);
    },
    getOption(search = null) {
      this.loading = true;
      const excludeIds = this.mapExcludeIds();
      
      this.Repository.options({
        limit: 10,
        offset: 0,
        search: search ? search : "",
        includeIds: this.loadedInitOptions ? "" : this.initOptions.join(","),
        excludeIds: this.loadedInitOptions ? excludeIds.join(',') : "",
      })
        .then((response) => {
          if (response?.data?.data?.list) {
            this.option = this.mergeArray(this.option, response.data.data.list);
          } else {
            this.option = this.mergeArray(this.option, response.data.data);
          }

          if (!this.loadedInitOptions) {
            if (this.initOptions) {
              this.selected = this.initOptions;
            }
          }
          this.loadedInitOptions = true;
        })
        .catch()
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>