export default [
  {
    key: 'image',
    initKey: 'image',
    label: 'field.image',
    type: 'async-single-image',
    path: 'item',
    pw: 200,
    ph: 200,
    fullWidth: false,
    cols: 12,
  },
  {
    key: 'companyId',
    label: 'field.company',
    rules: 'required',
    type: 'nAsynSingleSelection',
    repository: 'company',
    selectionKey: 'id',
    selectionLabel: 'name',
  },
  {
    key: 'departmentId',
    label: 'field.department',
    rules: 'required',
    type: 'nAsynSingleSelection',
    repository: 'department',
    selectionKey: 'id',
    selectionLabel: 'name',
  },
  {
    key: 'itemCategoryId',
    label: 'field.itemCategory',
    rules: 'required',
    type: 'nAsynSingleSelection',
    repository: 'itemCategory',
    selectionKey: 'id',
    selectionLabel: 'name',
  },
  {
    key: 'itemSectionId',
    label: 'field.itemSection',
    rules: 'required',
    type: 'nAsynSingleSelection',
    repository: 'itemSection',
    selectionKey: 'id',
    selectionLabel: 'name',
  },
  {
    key: 'itemTypeId',
    label: 'field.itemType',
    rules: 'required',
    type: 'nAsynSingleSelection',
    repository: 'itemType',
    selectionKey: 'id',
    selectionLabel: 'name',
  },
  {
    key: 'name',
    label: 'field.itemName',
    rules: 'required|max:200',
    type: 'text',
  },
  {
    key: 'cost',
    label: 'field.cost',
    rules: 'required|max_value:999999|decimal:2',
    type: 'currency',
    prefix: '$',
  },
  {
    key: 'unitId',
    label: 'field.unit',
    type: 'nAsynSingleSelection',
    repository: 'unit',
    selectionKey: 'id',
    selectionLabel: 'name',
  },
  {
    key: 'reorderLevel',
    label: 'field.reorderLevel',
    rules: 'required|integer|max_value:1000',
    type: 'text',
  },
  {
    key: 'specifications',
    label: 'field.specifications',
    rules: 'max:300',
    type: 'textarea',
    cols: 12,
  },
  {
    key: 'remark',
    label: 'field.remark',
    rules: 'max:300',
    type: 'textarea',
    cols: 12,
  },
  {
    key: 'isEnable',
    label: 'status.active',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
]
  