<template>
  <b-form
    @submit.prevent="submit"
    v-shortkey="['ctrl', 'enter']"
    @shortkey="submit()"
  >
    <slot></slot>
  </b-form>
</template>

<script>
import { BForm } from "bootstrap-vue";

export default {
  components: {
    BForm,
  },
  props: {
    readonly: {},
    form: {},
    disabled: {},
  },
  methods: {
    submit() {
      if (this.readonly || this.disabled) {
        this.$emit("submit");
        return;
      }

      this.confirm();
    },
    confirm() {
      this.$bvModal
        .msgBoxConfirm(this.$t("alert.unableToRevertAfterSave"), {
          title: this.$t("alert.areYouSure"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("button.yesSaveChanges"),
          cancelTitle: this.$t("button.cancel"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$emit("submit");
          }
        });
    },
  },
};
</script>

<style scoped>
</style>
