<template>
  <b-button
    :variant="variant"
    :disabled="loading"
    :type="type"
    @click="confirm"
    v-bind="$attrs"
  >
    <slot></slot>
  </b-button>
</template>

<script>
import { BButton } from "bootstrap-vue";

export default {
  components: {
    BButton,
  },
  props: {
    variant: {},
    type: {},
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    confirm() {
      this.$bvModal
        .msgBoxConfirm(this.$t("alert.unableToRevertAfterDelete"), {
          title: this.$t("alert.areYouSure"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.$t("button.yesDelete"),
          cancelTitle: this.$t("button.cancel"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$emit("confirm");
          }
        });
    },
  },
};
</script>

<style scoped>
</style>
